import React from 'react';
import { useState, useEffect } from 'react'
import {Link} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Header1 from './../Layout/Header';
import Footer from './../Layout/Footer';
import {Form}  from 'react-bootstrap';
import { jobAction } from '../../store/actions/AuthActions';         

function Componypostjobs(props){

	const [jobTitle, setJobtitle] = useState("");	
	const [jobType, setJobtype] = useState("");	
	const [experience, setExperience] = useState("");	
	const [salaryRange, setSalaryRange] = useState("");	
	const [requirements, setRequirements] = useState("");	
	const [jobDescription, setJobDescription] = useState("");	
	const [address, setAddress] = useState("");	
	const [auth, setAuth] = useState(false);

	let errorsObj = { jobTitle: '', jobType: '' , experience: '', salaryRange: '', requirements: '', address: ''};

    const [errors, setErrors] = useState(errorsObj);

	useEffect(() => {


		function verify(){

			const token = localStorage.getItem('token');
	
			if (token){
		
				setAuth(true);
		
			}
	
			console.log("Function is called!");
	
		}

		window.addEventListener("scroll", verify);
	}, [])




    const dispatch = useDispatch();

	function submitJob(e){


		e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };

		if (jobTitle.length < 10) {
            errorObj.jobTitle = 'Job Title must be atleas 10 characters long';
            error = true;
        }
        if (jobType.length < 3) {
            errorObj.jobType = 'Job Type must be atleast 3 characters long';
            error = true;
        }

		if (experience.length > 10){
			errorObj.experience = 'Experience must be a number smaller than 10';
			error = true;
		}

		
		if ((isNaN(salaryRange)) || salaryRange.length < 3) {
            errorObj.salaryRange = 'Salary Must be a number greater than 1000';
            error = true;
        }
        if (requirements.length < 25) {
            errorObj.requirements = 'Requirement must be atleast 25 characters long';
            error = true;
        }

		if (address.length < 10){
			errorObj.address = 'Address must be atleast 10 characters long';
			error = true;
		}

		setErrors(errorObj);
		if (error)return;
		
		console.log("submitJob Function");
        dispatch(jobAction(requirements, address, jobTitle, jobType, experience, salaryRange, jobDescription, props.history));

	}

	return(
		<>

			<Header1 />
			<div className="page-content bg-white" >
				<div className="content-block">
					<div className="section-full bg-white p-t50 p-b20">
						<div className="container">
							<div className="row">
								<div className="col-xl-3 col-lg-4 m-b30">
									<div className="sticky-top">
										<div className="candidate-info company-info">
											{/*<div className="candidate-detail text-center">
												<div className="canditate-des">
													<Link to={"#"}>
														<img alt="" src={require("./../../images/logo/icon3.jpg")} />
													</Link>
													<div className="upload-link" title="update" data-toggle="tooltip" data-placement="right">
														<input type="file" className="update-flie" />
														<i className="fa fa-pencil"></i>
													</div>
												</div>
												<div className="candidate-title">
													<h4 className="m-b5"><Link to={"#"}>@COMPANY</Link></h4>
												</div>
											</div>*/}

											{/*<ul>
												<li><Link to={"/company-profile"}>
													<i className="fa fa-user-o" aria-hidden="true"></i> 
													<span>Company Profile</span></Link></li>
												<li><Link to={"/company-post-jobs"} className="active">
													<i className="fa fa-file-text-o" aria-hidden="true"></i> 
													<span>Post A Job</span></Link></li>
												<li><Link to={"/company-transactions"}>
													<i className="fa fa-random" aria-hidden="true"></i>
													<span>Transactions</span></Link></li>
												<li><Link to={"/company-manage-job"}>
													<i className="fa fa-briefcase" aria-hidden="true"></i> 
													<span>Manage jobs</span></Link></li>
												<li><Link to={"/company-resume"}>
													<i className="fa fa-id-card-o" aria-hidden="true"></i>
													<span>Resume</span></Link></li>
												<li><Link to={"/jobs-change-password"}>
													<i className="fa fa-key" aria-hidden="true"></i> 
													<span>Change Password</span></Link></li>
												<li><Link to={"./"}>
													<i className="fa fa-sign-out" aria-hidden="true"></i> 
													<span>Log Out</span></Link></li>
											</ul>*/}
										</div>
									</div>
								</div>
								<div className="col-xl-10 col-lg-10 m-b30">
									<div className="job-bx submit-resume">
										{/*<div className="job-bx-title clearfix">
											<h5 className="font-weight-700 pull-left text-uppercase">Post A Job</h5>
											<Link to={"/company-profile"} className="site-button right-arrow button-sm float-right">Back</Link>
										</div>*/}

										<div className="job-bx-title clearfix">
											{/*<h5 className="font-weight-700 pull-left text-uppercase">Post A Job</h5><br></br>*/}
											<small>We need to verify your post. So please add <big className='text-dark'>Authentic</big> stuff.</small>
										
										</div>

										<form onSubmit={submitJob}>
											<div className="row">
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Job Title</label>
														<input type="text" value={jobTitle} className="form-control" placeholder="Enter Job Title" required="true"
														onChange={(e) => setJobtitle(e.target.value)}/>
													</div>
													<div className="text-danger">{errors.jobTitle && <div>{errors.jobTitle}</div>}</div>
												</div>
												{/*<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Your email</label>
														<input type="email" className="form-control" placeholder="info@gmail.com" />
													</div>
												</div>*/}
												{/*<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<label>Job Tags</label>
														<input type="text" className="form-control tags_input" />
														
													</div>
												</div>*/}
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Job Type</label>
														<input type="text" className="form-control" placeholder="e.g. Intern" required = "true"
														onChange={(e) => setJobtype(e.target.value)}/>
													</div>
													<div className="text-danger">{errors.jobType && <div>{errors.jobType}</div>}</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Experience</label>
														<input type="text" value = {experience} className="form-control" placeholder="e.g. 1 year" required = "true"
														onChange={(e) => setExperience(e.target.value)}/>
													</div>
													<div className="text-danger">{errors.experience && <div>{errors.experience}</div>}</div>
												</div>
												{/*<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Job Type</label>
														<Form.Control as="select" custom className="custom-select" 
														onSelect={(e) => setJobtype(e.target.value)}>
															<option>Full Time</option>
															<option>Part Time</option>
															<option>Internship</option>
															<option>Freelance</option>
														</Form.Control>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Experience</label>
														<Form.Control as="select" custom className="custom-select" 
														onSelect={(e) => setExperience(e.target.value)}>
															<option onSelect={(e) => setExperience(e.target.value)}>1 Years</option>
															<option onSelect={(e) => setExperience(e.target.value)}>2 Years</option>
															<option onSelect={(e) => setExperience(e.target.value)}>3 Years</option>
															<option onSelect={(e) => setExperience(e.target.value)}>4 Years</option>
															<option onSelect={(e) => setExperience(e.target.value)}>5 Years</option>
														</Form.Control>
													</div>
												</div>*/}
												{/*<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Minimum Salary ($):</label>
														<input type="email" className="form-control" placeholder="e.g. 10000" required = "true"
														onChange={(e) => setSalaryRange(e.target.value)}/>
													</div>
												</div>*/}
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Maximum Salary :</label>
														<input type="text" className="form-control" placeholder="e.g. 20000" required = "true"
														onChange={(e) => setSalaryRange(e.target.value)}/>
													</div>
													<div className="text-danger">{errors.salaryRange && <div>{errors.salaryRange}</div>}</div>
												</div>
												{/*<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Region</label>
														<Form.Control as="select" custom className="custom-select">
															<option>New York</option>
															<option>London</option>
															<option>Los Angeles</option>
														</Form.Control>
													</div>
												</div>*/}
												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<label>Job Requirements:</label>
														<textarea  className="form-control" required = "true" 
														onChange={(e) => setRequirements(e.target.value)}>
														</textarea>
													</div>
													<div className="text-danger">{errors.requirements && <div>{errors.requirements}</div>}</div>
												</div>

												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<label>Description (optional)</label>
														<textarea  className="form-control" 
														onChange={(e) => setJobDescription(e.target.value)}>
														</textarea>
													</div>
												</div>

												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Address</label>
														<input type="text" className="form-control" placeholder="Faridabad" required = "true" 
														onChange={(e) => setAddress(e.target.value)}/>
													</div>
													<div className="text-danger">{errors.address && <div>{errors.address}</div>}</div>
												</div>
												{/*<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<label>Upload File</label>
														<div className="custom-file">
															<p className="m-a0">
																<i className="fa fa-upload"></i>
																Upload File
															</p>
															<input type="file" className="site-button form-control" id="customFile" />
														</div>
													</div>
												</div>*/}
											</div>
											{auth && <button type="submit" className="site-button m-b30">Submit</button>}
											{!auth && 
											
											(
												<div className="text-end bottom"> 
													<Link to="/login" className="site-button button-md btn-sm text-white">Sign In</Link> 
												</div> 
										
											)}
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
			<Footer />
		</>
	)
}
export default Componypostjobs;