import React from 'react';
import { useState, useEffect } from 'react'
import {Link} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import Profilesidebar from './../Element/Profilesidebar';
import { profileAction } from '../../store/actions/AuthActions';         

 
function Jobprofile(props){

	const [name, setName] = useState("");	
	const [title, setTitle] = useState("");	
	const [languages, setLanguages] = useState("");	
	const [age, setAge] = useState("");	
	const [currentsalary, setCurrentsalary] = useState("");	
	const [expectedsalary, setExpectedsalary] = useState("");	
	const [description, setDescription] = useState("");	
	const [phone, setPhone] = useState("");		
	const [email, setEmail] = useState("");	
	const [country, setCountry] = useState("");	
	const [postcode, setPostcode] = useState("");	
	const [city, setCity] = useState("");	
	const [address, setAddress] = useState("");	
	const [resume, setResume] = useState("");	

	const [auth, setAuth] = useState(false);

	useEffect(() => {


		function verify(){

			const token = localStorage.getItem('token');
	
			if (token){
		
				setAuth(true);
		
			}
	
			console.log("Function is called!");
	
		}

		window.addEventListener("scroll", verify);
	}, [])

    const dispatch = useDispatch();

	function submitProfile(e){

		e.preventDefault();

		//const formData = new FormData();
		//formData.append('resume', resume);
		// formData.append('resumeName', resume.name);

		//console.log(formData.get("resume"));

		console.log("submit Profile");

        dispatch(profileAction(name, title, languages, age, currentsalary, expectedsalary, description, phone, email, country, postcode, city, address, resume, props.history));

	}

	return(
		<>	
			<Header />
			<div className="page-content bg-white">
				<div className="content-block">
					<div className="section-full bg-white browse-job p-t50 p-b20">
						<div className="container">
							<div className="row">
								{/*<Profilesidebar /> */}
								<div className="col-xl-9 col-lg-8 m-b30">
									<div className="job-bx job-profile">
										<div className="job-bx-title clearfix">
											<h5 className="font-weight-700 pull-left text-uppercase">Basic Information</h5>
											{/*<Link to={"./"} className="site-button right-arrow button-sm float-right">Back</Link>*/}
										</div>
										<form onSubmit={submitProfile} enctype='multipart/form-data'>
											<div className="row m-b30">
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Your Name:</label>
														<input type="text" value = {name} className="form-control" placeholder="Diary Greenson" required = "true"
														onChange={(e) => setName(e.target.value)}/> 
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Professional title:</label>
														<input type="text" value = {title} className="form-control" placeholder="Web Designer" required = "true"
														onChange={(e) => setTitle(e.target.value)}/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Languages:</label>
														<input type="text" value = {languages} className="form-control" placeholder="English" required = "true"
														onChange={(e) => setLanguages(e.target.value)}/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Age:</label>
														<input type="text" value = {age} className="form-control" placeholder="32 Year" required = "true"
														onChange={(e) => setAge(e.target.value)}/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Current Salary:</label>
														<input type="text" value = {currentsalary} className="form-control" placeholder="20000" required = "true"
														onChange={(e) => setCurrentsalary(e.target.value)}/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Expected Salary:</label>
														<input type="text" value = {expectedsalary} className="form-control" placeholder="25000" required = "true"
														onChange={(e) => setExpectedsalary(e.target.value)}/>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<label>Description:</label>
														<textarea  className="form-control" value = {description} onChange={(e) => setDescription(e.target.value)}>
														</textarea>
													</div>
												</div>
											</div>
											<div className="job-bx-title clearfix">
												<h5 className="font-weight-700 pull-left text-uppercase">Contact Information</h5>
											</div>
											<div className="row">
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Phone(10 Digits only):</label>
														<input type="text" value = {phone} className="form-control" placeholder="7206126236" required = "true"
														onChange={(e) => setPhone(e.target.value)}/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Email Address:</label>
														<input type="text" value = {email} className="form-control" placeholder="info@example.com" required = "true"
														onChange={(e) => setEmail(e.target.value)}/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Country:</label>
														<input type="text" value = {country} className="form-control" placeholder="India" required = "true"
														onChange={(e) => setCountry(e.target.value)}/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Postcode:</label>
														<input type="text" value = {postcode} className="form-control" placeholder="112233" required = "true"
														onChange={(e) => setPostcode(e.target.value)}/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>City:</label>
														<input type="text" value = {city} className="form-control" placeholder="Faridabad" required = "true"
														onChange={(e) => setCity(e.target.value)}/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Full Address:</label>
														<input type="text" value = {address} className="form-control" placeholder="Faridabad" required = "true"
														onChange={(e) => setAddress(e.target.value)}/>
													</div>
												</div>

												<div className="col-lg-8 col-md-8">
													<div className="form-group">
														<label>Resume Link:</label>
														<input type="url" className="form-control" required = "true" placeholder="https://drive.google.com/my-resume.docx"
														onChange={(e) => setResume(e.target.value)} />
													</div>
												</div>
											</div>

											{/*<div id="attach_resume_bx" className="job-bx bg-white m-b30">
												<h5 className="m-b10">Attach Resume</h5>
												<p>Resume is the most important document recruiters look for. Recruiters generally do not look at profiles without resumes.</p>
												<form className="attach-resume" enctype='multipart/form-data'>

													<div className="row">
														<div className="col-lg-12 col-md-12">
															<div className="form-group">
																<div className="custom-file">
																	<p className="m-auto align-self-center">
																	   <i className="fa fa-upload"></i>
																	   Upload Resume File size is 3 MB
	</p>
																	<input type="url" className="site-button form-control" id="customFile" required = "true" 
																	onChange={(e) => setResume(e.target.files[0])} />
																</div>
															</div>
														</div>
													</div>

												</form>
	</div>*/}

											
											{auth && <button className="site-button m-b30" type="submit">Save</button>}
											{!auth && 
											
											(
												<div className="text-end bottom"> 
													<Link to="/login" className="site-button button-md btn-sm text-white">Sign In</Link> 
												</div> 
										
											)}
										</form>
									</div>    
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />	
		</>
	)
} 
export default Jobprofile;