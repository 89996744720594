import React from 'react';
import {Link} from 'react-router-dom';

function Jobcategories(){
	return(
		<div className="row sp20">
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="ti-location-pin"></i></div>
						<a href="#section" className="dez-tilte">Design, Art & Multimedia</a>
						{/*<h6 className="dez-tilte">Design, Art & Multimedia</h6>*/}

						{/*<p className="m-a0">18 Open Positions</p>*/}
						<div className="rotate-icon"><i className="ti-location-pin"></i></div> 
					</div>
				</div>				
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="ti-wand"></i></div>
						<a href="#section" className="dez-tilte">Education Training</a>

						{/*<Link to={"/company-manage-job"} className="dez-tilte">Education Training</Link>*/}
						{/*<h6 className="dez-tilte">Education Training</h6>*/}

						<div className="rotate-icon"><i className="ti-wand"></i></div> 
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="ti-wallet"></i></div>
						<a href="#section" className="dez-tilte">Accounting / Finance</a>

						{/*<Link to={"/company-manage-job"} className="dez-tilte">Accounting / Finance</Link>*/}
						{/*<h6 className="dez-tilte">Accounting / Finance</h6>*/}

						<div className="rotate-icon"><i className="ti-wallet"></i></div> 
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="ti-cloud-up"></i></div>
						<a href="#section" className="dez-tilte">Human Resource</a>

						{/*<Link to={"/company-manage-job"} className="dez-tilte">Human Resource</Link>*/}
						{/*<h6 className="dez-tilte">Human Resource</h6>*/}

						<div className="rotate-icon"><i className="ti-cloud-up"></i></div> 
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="ti-bar-chart"></i></div>
						<a href="#section" className="dez-tilte">Software Development</a>

						{/*<Link to={"/company-manage-job"} className="dez-tilte">Software Development</Link>*/}
						{/*<h6 className="dez-tilte">Software Development</h6>*/}

						<div className="rotate-icon"><i className="ti-bar-chart"></i></div> 
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="ti-tablet"></i></div>
						{/*<Link to={"/company-manage-job"} className="dez-tilte">Marketing</Link>*/}
						<a href="#section" className="dez-tilte">Marketing</a>
						
						
						{/*<h6 className="dez-tilte">Marketing</h6>*/}
						<div className="rotate-icon"><i className="ti-tablet"></i></div> 
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="ti-camera"></i></div>
						<a href="#section" className="dez-tilte">Content Writing</a>

						{/*<Link to={"/company-manage-job"} className="dez-tilte">Content Writing</Link>*/}
						{/*<h6 className="dez-tilte">Content Writing</h6>*/}
						<div className="rotate-icon"><i className="ti-camera"></i></div> 
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="ti-panel"></i></div>
						<a href="#section" className="dez-tilte">Software Testing</a>

						{/*<Link to={"/company-manage-job"} className="dez-tilte">Software Testing</Link>*/}
						{/*<h6 className="dez-tilte">Software Testing</h6>*/}
						<div className="rotate-icon"><i className="ti-panel"></i></div> 
					</div>
				</div>
			</div>
			{/*
			<div className="col-lg-12 text-center m-t30">
				<button className="site-button radius-xl">All Categories</button>
	</div>*/}
		</div>
	)
}

export default Jobcategories;