import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
    auth,
    job,
    profile,
} from '../../services/AuthService';

import axios from 'axios';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(username, email, password, history) {

    return (dispatch) => {
        signUp(username, email, password)
        .then((response) => {


            dispatch(confirmedSignupAction("Account created successfuly!"));
            history.push('/login');
        })
        .catch((error) => {
            // console.log(error);
            //  const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction("Username must be unique."));
        });
    };
}

export function jobAction(requirements, address, jobTitle, jobType, experience, salaryRange, jobDescription, history){

    return (dispatch) => {

        job(requirements, address, jobTitle, jobType, experience, salaryRange, jobDescription)
        .then((response) => {

            console.log("Action calling.")

            alert("New Job post added.");
            history.push('/index-2');
        })
        .catch((error) =>{

            alert("Some error occurred.");
        })
    }
}

export function profileAction(name, title, languages, age, currentSalary, expectedSalary, description, phone, email, country, postcode, city, address, resume, history){

    return (dispatch) => {
        profile(name, title, languages, age, currentSalary, expectedSalary, description, phone, email, country, postcode, city, address, resume)
        .then((response) => {

            console.log(response.data)

            if (response.data["status"] === false){

                console.log("Some error occurred.")
                alert("Please fill the information correctly.")


            }

            else{


                console.log("ProfileAction calling.")

                // dispatch(confirmedSignupAction("New Resume added."));
                alert("Your Information has been saved!")
                history.push('/index-2');

            }


        })
        .catch((error) =>{

            console.log(error)
            dispatch(confirmedSignupAction("Can't add your resume now."));
        })
    }
}

export function logout(history) {


    localStorage.removeItem('token');
    localStorage.removeItem('username');

    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(username, password, history) {
    return (dispatch) => {

        localStorage.setItem('username', JSON.stringify(username));
        
        login(username, password)
            .then((response) => {
      
                // console.log(response);
                if (response.data.errors){

                    dispatch(loginFailedAction("Credentials were wrong."));
                    console.log(response.data.errors);
                }

                else{

                    console.log(response.data["access"])
                    saveTokenInLocalStorage(response.data);

                    runLogoutTimer(
                        dispatch,
                        300000,    
                        history,
                    );
    
                    dispatch(loginConfirmedAction("Login Successfuly."));
                    history.push('/index-2');  


                }
             
            })
            .catch((error) => {

                // const errorMessage = formatError(error);
                dispatch(loginFailedAction("Credentials were wrong."));
                console.log(error);

            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

