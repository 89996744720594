import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';

export function signUp(username, email, password) {
  
    // axios call
    const postData = {
        "username": username,
        "email": email,
        "password": password,
        // returnSecureToken: true,
    };
    return axios.post(
        `https://hireeasibackapi.herokuapp.com/accounts/api/createuser/`,
        postData,
    );
}

export function job(requirements, address, jobTitle, jobType, experience, salaryRange, jobDescription){

    const postData = {requirements, address, jobTitle, jobType, 'experiance': experience, salaryRange, jobDescription}

    return axios.post(`https://hireeasibackapi.herokuapp.com/accounts/api/create-job/`, postData);
}



export function profile(name, title, languages, age, currentSalary, expectedSalary, description, phone, email, country, postcode, city, address, resume){

    const user = localStorage.getItem("username");

    const postData = {'your_name' : name, 'professional_title' : title, languages, age, 'current_salary': currentSalary, 'expected_salary': expectedSalary, description, phone, email, country, postcode, city, address, resume}

    const config = {
        headers: {
            "Content-type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("token")}`,
        },
    };


    return axios.post(`https://hireeasibackapi.herokuapp.com/accounts/api/create-employee/`, postData, config);
}



export function login(username, password) {
    const postData = {
        username: username,
        password: password,
        returnSecureToken: true,
    };

    return axios.post(
        `https://hireeasibackapi.herokuapp.com/accounts/api/token/`,
        postData,
    )
}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
           swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {

    localStorage.setItem('token', JSON.stringify(tokenDetails["access"]))
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(logout(history));
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, history);
}


