import React from 'react';
import {Link} from 'react-router-dom';

function Footer(){
	return(
		<footer className="site-footer">




			<div className="footer-top">
				<div className="container">
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 mx-auto">
							<div className="widget">
								<img src={require("./../../images/logo1.jpg")} width="180" className="m-b15" alt=""/>
								<p className="text-capitalize m-b20">Connect with US.</p>
								<div className="subscribe-form m-b20">
									{/*<form className="dzSubscribe" action="script/mailchamp.php" method="post">
										<div className="dzSubscribeMsg"></div>
										<div className="input-group">
											<input name="dzEmail" required="required"  className="form-control" placeholder="Your Email Address" type="email" />
											<span className="input-group-btn">
												<button name="submit" value="Submit" type="submit" className="site-button radius-xl">Subscribe</button>
											</span> 
										</div>
									</form>*/}
								</div>
								<ul className="list-inline m-a0">
									<li><a href="https://www.linkedin.com/company/interneasy/" className="site-button white linkedin circle "><i className="fa fa-linkedin"></i></a></li>
									<li><a href='https://www.instagram.com/hireeasi/' className="site-button white instagram circle "><i className="fa fa-instagram"></i></a></li>
									<li><a href = "mailto:info@hireeasi.com" className="site-button white email circle "><i className="fa fa-envelope"></i></a></li>
                        			

								</ul>
							</div>
						</div>
						<div className="col-xl-5 col-lg-5 col-md-8 col-sm-8 col-12">
							<div className="widget border-0">
								<h5 className="m-b30 text-white">Our Office</h5>

								{/*<li><Link to={'/about-us'}>About US</Link></li>*/}


								<iframe 
										src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3510.714511856319!2d77.31370621440068!3d28.36747960277969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdc71f6e9f557%3A0xeb301eec9ff18517!2z4KSc4KWHLuCkuOClgC4g4KSs4KWL4KS4IOCkr-ClgeCkqOCkv-CkteCksOCljeCkuOCkv-Ckn-ClgCDgpJHgpKsg4KS44KS-4KSH4KSC4KS4IOCkkOCkguCkoSDgpJ_gpYjgpJXgpKjgpYvgpLLgpYvgpJzgpYAsIOCkteCkvuCkry4g4KSQ4KSuLiDgpLjgpYAuIOCkjy4!5e0!3m2!1shi!2sin!4v1655190444516!5m2!1shi!2sin" 
										width="180" height="140" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">

								</iframe>



								{/*<ul className="list-2 list-line">
									<li><Link to={'#'}>Terms of Serice</Link></li>
									<li><Link to={'#'}>For Employers</Link></li>
									<li><Link to={'#'}>Contact Us</Link></li>
									
								</ul>*/}
							</div>
						</div>

		
						{/*<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="widget border-0">
								<h5 className="m-b30 text-white">Our Office</h5>
								<ul className="list-2 w10 list-line">
									<li><Link to={''}>US Jobs</Link></li>
									<li><Link to={''}>Canada Jobs</Link></li>
									<li><Link to={''}>UK Jobs</Link></li>
									<li><Link to={''}>Emplois en Fnce</Link></li>
								</ul>
							</div>
						</div>*/}

					</div>
				</div>
			</div>
			
			<div className="footer-bottom">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 text-center">
							<span> © Copyright by <i className="fa fa-heart m-lr5 text-red heart"></i>
							<Link to={'#'}>HireEasi </Link> All rights reserved.</span> 
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer;